import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Stack,
  Tabs,
  Tab,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Divider,
} from "@mui/material"
import { DotIcon, readingTime } from "../components/BlogPostGrid"
import { StaticImage } from "gatsby-plugin-image"
import LocalizedLink from "../components/LocalizedLink"
import { FormattedMessage } from "react-intl"
import slugify from "slugify"
import { MarkdownContainer } from "./blog/_article"
import { FaTwitter, FaLinkedinIn } from "react-icons/fa"
import IconContainer from "../components/TeamsCommitteeContributorsPage/IconContainer"
import remarkUnlink from "remark-unlink"

const tabs = [
  { id: 0, label: "Biography" },
  { id: 1, label: "Déclaration d’intérêts" },
  { id: 2, label: "Articles" },
]

const AuthorPageTemplate = ({ data }) => {
  const [value, setValue] = useState(0)
  const { blogPosts } = data

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [limit, setLimit] = useState(7)

  const posts = blogPosts.nodes.slice(0, limit)

  const handleShowNextPostsBatch = () => {
    setLimit(e => (e += 6))
  }

  return (
    <Stack
      component="section"
      direction={{ base: "column", md: "row" }}
      alignItems={{ md: "flex-start" }}
      spacing={{ base: 2, md: 4, lg: 8 }}
      mx={2}
      pt={8}
    >
      <Box width={{ base: "100", md: "270px" }} flexShrink="0">
        <Stack direction={{ base: "row", md: "column" }}>
          {/* Profile Image */}
          <Box mr={{ base: 2, sm: 3, md: 0 }}>
            <StaticImage
              src="../../static/assets/author-dummy.png"
              alt="author profile image"
              layout="fixed"
              width={100}
              height={100}
              style={{ borderRadius: "50%", marginBottom: "1rem" }}
            />
          </Box>
          {/* Profile Info */}
          <Box>
            <Box mb="0.5rem">
              <Typography color="primary" fontSize="1.125rem" fontWeight="bold">
                Michel Durand
              </Typography>
              <Typography color="#464646" fontWeight="300">
                Rédacteur en chef
              </Typography>
              <Typography
                component="a"
                href="mailto:michel.durand@captain-team.net"
                color="#464646"
                fontWeight="300"
                sx={{ textDecorationColor: "#46464650" }}
              >
                michel.durand@captain-team.net
              </Typography>
            </Box>
            {/* Social Icons */}
            <Stack direction="row" spacing={1} py={1} mb={{ base: 2, md: 0 }}>
              <IconContainer href="https://twitter.com">
                <FaTwitter size="17px" color="#fff" />
              </IconContainer>
              <IconContainer href="https://linkedin.com">
                <FaLinkedinIn size="17px" color="#fff" />
              </IconContainer>
            </Stack>
          </Box>
        </Stack>
        {/* Divider */}
        <Divider sx={{ my: 5, display: { base: "none", md: "block" } }} />
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eleifend odio
          phasellus dui in hendrerit sociis ut turpis mattis.{" "}
        </Typography>
      </Box>

      <Box width="100%" minHeight="200px">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Authors Tabs about Biography, articles etc."
          >
            {tabs.map((tab, i) => (
              <Tab
                key={tab.id}
                disableRipple
                label={tab.label}
                {...a11yProps(tab.id)}
                sx={{
                  fontWeight: value === tab.id && "bold",
                  mb: "-55px",
                  px: "0!important",
                  ml: i > 0 && 2,
                  minWidth: "initial!important",
                  textTransform: "none",
                }}
              />
            ))}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna
            consectetur molestie mattis odio. Pharetra dignissim semper duis
            enim etiam. Fames parturient aliquet aenean orci. Accumsan sit nunc,
            mi molestie iaculis diam. Bibendum enim massa maecenas amet volutpat
            urna integer mattis. Congue senectus ut ornare molestie placerat
            enim erat. Massa non feugiat enim ut velit quisque suspendisse
            blandit. Porta ultrices eget in laoreet condimentum eu convallis.
            Nibh ullamcorper scelerisque vel tincidunt eu est et tempor. Nulla
            vulputate suspendisse urna enim justo, urna venenatis.
          </Typography>

          <Typography>
            Sed amet neque, adipiscing sapien sit ac nunc mattis amet. Id
            vestibulum, lectus vel scelerisque. Dis augue donec imperdiet sit
            commodo. Tempor orci sapien et iaculis velit nullam urna volutpat,
            at. Quis ipsum neque, egestas lobortis hendrerit. Nam euismod
            quisque lectus lectus malesuada eu dolor ultrices id. Eleifend
            laoreet pellentesque nunc, leo suspendisse. At sed id et ultricies.
            Tortor et donec quis vitae enim vitae consequat. Sit adipiscing eu
            rhoncus et tincidunt.
          </Typography>

          <Typography>
            Quis in fames dolor interdum ut gravida ultricies nibh varius.
            Ultrices euismod consequat quis pellentesque at auctor sed ultrices
            massa. Tempus ac accumsan commodo faucibus venenatis urna, augue
            blandit vitae. Cursus vestibulum a morbi pulvinar odio ut a, neque
            turpis. Ac lobortis tortor in id eu nibh semper cursus. Ut
            sollicitudin volutpat, ac consequat sem nulla sit nulla. Vel in
            gravida in aliquam quis. Sed eget dui, nisl, elit lacus vel, ut sit
            et. Mauris ipsum feugiat neque, nulla libero dolor viverra gravida
            vitae.
          </Typography>

          <Typography>
            Nulla elementum posuere sed tellus, sit amet fermentum. Augue urna
            aliquam elit et ut rhoncus in. Consequat, sed sit adipiscing
            scelerisque vel quisque non faucibus. Purus ipsum lacus adipiscing
            lectus enim sed neque non. Velit non ut id accumsan sed magna enim,
            eget facilisis. Auctor varius cursus nibh mattis rhoncus, habitant.
          </Typography>

          <Typography>
            Erat neque, platea pharetra, auctor nulla ultrices. Curabitur
            imperdiet pellentesque quam gravida vel libero amet. Aliquam.
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography>
            Sed amet neque, adipiscing sapien sit ac nunc mattis amet. Id
            vestibulum, lectus vel scelerisque. Dis augue donec imperdiet sit
            commodo. Tempor orci sapien et iaculis velit nullam urna volutpat,
            at. Quis ipsum neque, egestas lobortis hendrerit. Nam euismod
            quisque lectus lectus malesuada eu dolor ultrices id. Eleifend
            laoreet pellentesque nunc, leo suspendisse. At sed id et ultricies.
            Tortor et donec quis vitae enim vitae consequat. Sit adipiscing eu
            rhoncus et tincidunt.
          </Typography>

          <Typography>
            Quis in fames dolor interdum ut gravida ultricies nibh varius.
            Ultrices euismod consequat quis pellentesque at auctor sed ultrices
            massa. Tempus ac accumsan commodo faucibus venenatis urna, augue
            blandit vitae. Cursus vestibulum a morbi pulvinar odio ut a, neque
            turpis. Ac lobortis tortor in id eu nibh semper cursus. Ut
            sollicitudin volutpat, ac consequat sem nulla sit nulla. Vel in
            gravida in aliquam quis. Sed eget dui, nisl, elit lacus vel, ut sit
            et. Mauris ipsum feugiat neque, nulla libero dolor viverra gravida
            vitae.
          </Typography>

          <Typography>
            Sed amet neque, adipiscing sapien sit ac nunc mattis amet. Id
            vestibulum, lectus vel scelerisque. Dis augue donec imperdiet sit
            commodo. Tempor orci sapien et iaculis velit nullam urna volutpat,
            at. Quis ipsu malesuada eu dolor ultrices id. Eleifend laoreet
            pellentesque nunc, leo suspendisse. At sed id et ultricies. Tortor
            et donec quis vitae enim vitae consequat. Sit adipiscing eu rhoncus
            et tincidunt.
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {posts.map(post => {
            return <Post key={post.id} {...post} />
          })}
          {limit < blogPosts.nodes.length && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt="-194px"
              mb="-24px"
              position="relative"
              zIndex={2}
              height="20vh"
              sx={{
                background:
                  "linear-gradient(179.32deg, rgba(255, 255, 255, 0.75) -1.02%, #FFFFFF 35.66%)",
              }}
            >
              <Button
                variant="text"
                onClick={handleShowNextPostsBatch}
                sx={{
                  textTransform: "none",
                }}
              >
                <Typography
                  sx={{
                    pb: "0px",
                    borderBottomColor: "primary",
                    borderBottom: "1px solid",
                  }}
                >
                  Voir plus
                </Typography>
              </Button>
            </Stack>
          )}
        </TabPanel>
      </Box>
    </Stack>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3, "& > p": { mb: 2 } }}>{children}</Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

export default AuthorPageTemplate

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Post = ({ title, published_at, image, content, excerpt }) => {
  const theme = useTheme()
  const mediumScreens = useMediaQuery(theme.breakpoints.up("md"))

  const slug = slugify(title)

  const date = new Date(published_at)
  const dateString = `${published_at
    .slice(5, 10)
    .replace("-", "/")}/${date.getFullYear()}`

  const timeToRead = readingTime(content)

  return (
    <Box width="100%" mb={4} overflow="hidden">
      <LocalizedLink
        to={`/free-access/${slug}`}
        style={{ textDecoration: "none" }}
      >
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Stack
            direction="column"
            justifyContent="space-between"
            textAlign="left"
          >
            <Box>
              <Typography component="span" fontSize="0.75rem" color="#707070">
                {dateString} <DotIcon /> {timeToRead.readingTime} min
              </Typography>

              <Typography
                color="#1B1A3C"
                lineHeight="1.375rem"
                fontWeight="bold"
                mb={0}
              >
                {title}
              </Typography>
              {mediumScreens && (
                <Box
                  sx={{
                    "& p": {
                      mt: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "3",
                      WebkitBoxOrient: "vertical",
                    },
                  }}
                >
                  <MarkdownContainer
                    color="#464646"
                    fontSize="0.875rem"
                    lineHeight="1.125rem"
                    markdown={excerpt}
                    plugins={[remarkUnlink]}
                  />
                </Box>
              )}
            </Box>
            <Typography
              component="span"
              color="#37459C"
              fontSize="0.75rem"
              sx={{ textDecoration: "underline" }}
            >
              <FormattedMessage id="read_more_link" />
            </Typography>
          </Stack>
          <Box width="130px" height="130px" flexShrink="0">
            {image && (
              <img
                src={image.url}
                alt={image.url}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            )}
            {/* <GatsbyImage
              image={localImage.childImageSharp.gatsbyImageData}
              alt={image.title}
            /> */}
          </Box>
        </Stack>
      </LocalizedLink>
    </Box>
  )
}

Post.propTypes = {
  title: PropTypes.string.isRequired,
  published_at: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
}
