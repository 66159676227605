import React from "react"
import Layout from "../layouts/fr"
import AuthorPageTemplate from "../templates/author-page"
import { graphql } from "gatsby"

const AuthorPage = props => {
  return (
    <Layout location={props.location} title="Author Template">
      <AuthorPageTemplate {...props} />
    </Layout>
  )
}

export default AuthorPage

export const query = graphql`
  query AuthorPage {
    blogPosts: allBlogPostFr(limit: 24) {
      nodes {
        published_at
        id
        title
        content
        excerpt
        image {
          title
          url
        }
      }
    }
  }
`
